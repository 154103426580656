import {NavLink} from 'react-router-dom'
import image from '../assets/me.JPG'
import './Header.css'
import '../index.css'

function Header(){
    function toggleDarkMode(){
        if(document.getElementById("darkModeSwitch").checked){
            console.log("DARK MODE ON")
            document.body.classList.toggle("darkMode")
        }
        else{
            console.log("DARK MODE OFF")
            document.body.classList.toggle("darkMode")
        }
    }

    window.onload = function() {
        document.body.classList = "darkMode"
        document.getElementById("darkModeSwitch").checked = true
    };

    return(
        <div>
            <section className="header">
                <div className="left">
                    <h1 className="name" id="long-name">Benjamin Au</h1>
                    <h1 className="name" id="short-name">Ben Au</h1>
                    <p className="name_label">Developer</p>
                    <nav className="navbar">
                        <ol className="navbar-links">
                            <li><NavLink to="/home">Home</NavLink></li>
                            <li><NavLink to="/resume">Resume</NavLink></li>
                            <li><NavLink to="/projects">Projects</NavLink></li>
                            <li><NavLink to="/contact">Contact</NavLink></li>
                        </ol>
                    </nav>
                </div>

                <div className="pageMode">
                    <div className="left">
                    <label className="switch">
                        <input id = "darkModeSwitch" type="checkbox" onClick={toggleDarkMode}></input>
                        <span className="slider"></span>
                    </label>
                    </div>
                </div>
            </section>

            <section className="image">
                <div className="right">
                    <img src={image} className="profile-image" alt="me"></img>
                </div>
            </section>
        </div>
    )
}

export default Header;