function Contact(){
    return(
        <div>
            <section className="content" id="contact">
                <ol style={{rowGap: '1px'}}>
                    <li>
                        <span className="contact-label">Email: </span>
                        <span>benjaminsau95@gmail.com</span>
                    </li>
                    <li>
                        <span className="contact-label">Linkedin: </span>
                        <a href="https://www.linkedin.com/in/benjaminsau/" target='_blank'  rel="noopener noreferrer" style={{textDecoration:"underline"}}>
                            benjaminsau
                        </a>
                    </li>
                </ol>
            
            </section>
        </div>
    );
}

export default Contact;