function Resume(){
    return(
        <div>
            <section className="content" id="resume">
                <h2>Technical Skills</h2>
                <p>Java, C++, Python, JavaScript/TypeScript, HTML/CSS, C, R</p>

                <h2>Tools</h2>
                <p>AWS, Docker, SQL, Git/GitHub, Figma</p>

                <h2>Languages</h2>
                <p>English (Native Language), Cantonese (Conversational Proficiency)</p>

                <h2>Work Experience</h2>
                <h3>theCoderSchool (Remote): April 2022 - Present</h3>
                <ul>
                    <li>Conduct private coaching sessions, teaching languages such as JavaScript, Python, and C++</li>
                    <li>Tailor curriculum and projects to match individual student interests and skill levels</li>
                    <li>Effectively communicate complex technical concepts to students of all ages, ensuring understanding and growth</li>
                </ul>

                <h3>Nike (San Leandro, CA): July 2017 - January 2019</h3>
                <ul>
                    <li>Provided excellent customer service in a fast-paced retail environment, serving thousands of customers daily</li>
                    <li>Maintained open communication with coworkers to promptly address and resolve issues</li>
                    <li>Awarded employee of the year for 2018, showcasing dedication and outstanding performance</li>
                </ul>

                <h3>Kaleris (Alameda, CA): September 2014 - January 2015</h3>
                <ul>
                    <li>Played a key role in designing and implementing test plans for supply chain software used by Fortune-500 manufacturers</li>
                    <li>Gained experience in SCRUM and Agile methodology while collaborating closely with the engineering team</li>
                    <li>Identified and resolved multiple software bugs and usability issues during black box testing</li>
                </ul>

                <h2>Education</h2>
                <h3>San Francisco State University 21'</h3>
                <h3 style={{marginTop: "1px"}}>B.S. Computer Science</h3>
                <p>Relevant Coursework:</p>
                <ul style={{rowGap: "2px"}}>
                    <li>Software Development</li>
                    <li>Data Structures</li>
                    <li>Analysis of Algorithms</li>
                    <li>Security and Data Privacy</li>
                    <li>Internet Application Design</li>
                    <li>Human Computer Interaction</li>
                </ul>
            </section>
        </div>
    );
}

export default Resume;