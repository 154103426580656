function Projects(){
    return(
        <div>
            <section className="content" id="projects">
                <h2>Roomie</h2>
                <h3>Full Stack Web Application (HTML/CSS, JavaScript, Express, AWS)</h3>
                <p>Roomie is a roomate/room finder developed over the course of a semester during my senior year.</p>
                <p>Features:</p>
                <ul>
                    <li>user accounts (register, sign in, sign out)</li>
                    <li>fully functional search for room listings or roommates</li>
                    <li>database of room listings</li>
                    <li>database of user profiles</li>
                    <li>ability to create new room listings (with image upload)</li>
                    <li>user-to-user messaging</li>
                    <li>interactive map with clickable pins linked to listings (using Google Maps API)</li>
                </ul>

                <h2>Linux File System</h2>
                <h3>Based on Linux's EXT file system (C)</h3>
                <p>Created essential structures:</p>
                <ul>
                    <li>Volume Control Block</li>
                    <li>Free-space bitmap</li>
                    <li>Directory</li>
                </ul>
                <p style={{marginTop: "5px;"}}>Implemented shell commands:</p>
                <ul>
                    <li>list files</li>
                    <li>copy</li>
                    <li>move</li>
                    <li>remove</li>
                    <li>change directory</li>
                    <li>print working directory</li>
                    <li>history</li>
                    <li>help</li>
                </ul>

                <h2><a 
                    href="/rotations/index.html" 
                    target="_blank" 
                    rel="noopener noreferrer">
                        Rotations (click to try!)
                </a></h2>
                <h3>JS whiteboard app utilizing p5.js library</h3>
                <ul>
                    <li>Built this to help visualize volleyball rotations for my recreational league team</li>
                    <li>Compatible with mobile devices!</li>
                </ul>
            </section>
        </div>
    );
}

export default Projects;